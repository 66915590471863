@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

html,
body {
  margin: 0;
  height: 100vh;
  min-height: 100vh;
  background-color: #f6f7fb;
  font-family: "DM Sans", sans-serif;
}

.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}

.text-gradient {
  background: linear-gradient(to right, #0e1c33, #085ed8, #2894ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-gradient-secondary {
  background: linear-gradient(to right, #085ed8, #0e1c33, #2894ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.background-gradient {
  background: linear-gradient(to left, #65a8f5, #308cf5 #085ed8);
}
